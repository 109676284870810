<template>
  <b-row class="wrapper presentation-page">
    <b-col md="12">
      <b-row class="page-header clear-filter">
        <b-col md="12">
          <b-row class="rellax-header rellax-header-sky">
            <b-col md="12">
              <parallax
                class="page-header-image"
                style="background-image: url('img/Brand/nuk-pro-back-sky.jpg')"
              ></parallax>
            </b-col>
          </b-row>
          <b-row class="rellax-header rellax-header-douce" data-rellax-speed="0">
            <b-col md="12">
              <parallax
                class="page-header-image page-header-city"
                style="background-image: url('img/Brand/DouceEscalePlainBW_small.png')"
              ></parallax>
            </b-col>
          </b-row>
          <b-row class="rellax-text-container rellax-text">
            <b-col md="12">
              <h1 class="h1-seo" data-rellax-speed="-2">Douce Escale</h1>
            </b-col>
          </b-row>
          <h2
            class="h3-description rellax-text"
            data-rellax-speed="-1"
          >Institut de Beauté pour Femme.</h2>
          <h6 class="category category-absolute rellax-text" data-rellax-speed="-2">
            <parallax>
              Designed and coded by
              <a href="mailto:laurent.peny@gmail.com">LCP</a>.
            </parallax>
          </h6>
        </b-col>
      </b-row>
      <b-row class="container pb-3"></b-row>
      <b-row
        v-for="(douceEscale, index) in douceEscaleSoins.douceEscale"
        :key="index"
        class="col-lg-10 ml-auto mr-auto p-0"
        :id="douceEscale.id"
      >
        <b-col v-if="!douceEscale.right" md="1"></b-col>
        <b-col md="11" class="p-0">
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col v-if="!douceEscale.right" md="4">
                <b-img
                  :src="douceEscale.image"
                  :alt="douceEscale['image-alt']"
                  fluid
                  class="card-img"
                ></b-img>
              </b-col>
              <b-col md="8">
                <b-card-body>
                  <b-row v-if="douceEscale['marque-img'] !== ''" class="card-header">
                    <b-col md="6">
                      <h2 class="category text-secondary">{{douceEscale.category}}</h2>
                    </b-col>
                    <b-col v-if="douceEscale['marque-img'] !== ''" md="6">
                      <a :href="douceEscale['marque-url']" target="_blank">
                        <b-img :src="douceEscale['marque-img']" class="brand-img"></b-img>
                      </a>
                    </b-col>
                  </b-row>
                  <b-row v-else class="card-header">
                    <b-col>
                      <h2 class="category text-secondary">{{douceEscale.category}}</h2>
                    </b-col>
                  </b-row>
                  <b-card-text>
                    <b-row v-for="(soin, indexSoin) in douceEscale.soins" :key="indexSoin" no-gutters>
                      <b-col>
                        <b-row v-if="soin.SectionTitle == true">
                          <b-col cols="12">
                            <h4>{{soin.soin}}</h4>
                          </b-col>
                        </b-row>
                        <b-row v-else>
                          <b-col cols="8">
                            <p class="p-0 m-0">{{soin.soin}}</p>
                          </b-col>
                          <b-col cols="2" class="p-0 m-0">
                            <p class="p-0 m-0">{{soin.duree}}</p>
                          </b-col>
                          <b-col cols="2" class="pl-0 m-0 text-right">
                            <p class="p-0 m-0 category">{{soin.prix}}</p>
                          </b-col>
                        </b-row>
                        <b-row v-if="soin.note">
                          <b-col cols="1"></b-col>
                          <b-col><p
                            class="description"
                            style="white-space: pre-wrap"
                          >{{soin.note}}</p>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row class="bottom-nav p-4" style="max-height:50px">
                      <b-col ms="12" :class="shareClass(douceEscale.right)">
                        <b-button
                          pill
                          size="sm"
                          class="share-button p-2"
                          style="background-color: #3b5998;"
                          data-href="https://douceescaleinstitut.fr/"
                          :href="`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdouceescaleinstitut.fr%2F%23${douceEscale.id}&amp;src=sdkpreparse`"
                          target="popup"
                        >
                          <i class="fab fa-facebook-square" style="font-size: 1.5em;"></i>
                          <span class="d-none d-lg-inline-flex">&nbsp;Facebook</span>
                        </b-button>
                        <b-button
                          size="sm"
                          class="share-button p-2"
                          style="background-color: #00acee;"
                          :href="`https://twitter.com/intent/tweet?text=Douce%20Éscale%20-%20Institut%20de%20Beauté%20pour%20Femme.&amp;original_referer=https%3A%2F%2Fdouceescaleinstitut.fr&amp;ref_src=twsrc%5Etfw&amp;url=https%3A%2F%2Fdouceescaleinstitut.fr%2F%23${douceEscale.id}&amp;hashtags=DouceEscaleInstitut,DouceEscale,Ménétrol`"
                          data-hashtags="douceEscale"
                          data-show-count="false"
                        >
                          <i class="fab fab fa-twitter" style="font-size: 1.5em;"></i>
                          <span class="d-none d-lg-inline-flex">&nbsp;Twitter</span>
                        </b-button>
                        <!--b-button size="sm" class="share-button btn-primary p-2"
                          href="mailto:?subject=Douce%20Éscale%20-%20Institut%20de%20Beauté%20pour%20Femme.&amp;body=Douce%20Éscale%20-%20Institut%20de%20Beauté%20pour%20Femme.%0A%0A%0Ahttps%3A%2F%2Fdouceescaleinstitut.fr%2FSoinsVisage">
                          <i class="far fa-envelope" style="font-size: 1.5em;"></i><span class="d-none d-lg-inline-flex">&nbsp;Email</span>
                        </b-button-->
                        <b-button
                          v-if="navigatorShare"
                          size="sm"
                          class="share-button btn-primary p-2"
                          @click="share(douceEscale.id)"
                        >
                          <i class="fa fa-share-alt" style="font-size: 1.5em;"></i>
                          <span class="d-none d-lg-inline-flex">&nbsp;Share</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-col>
              <b-col v-if="douceEscale.right" md="4">
                <b-img
                  :src="douceEscale.image"
                  :alt="douceEscale['image-alt']"
                  fluid
                  class="card-img"
                ></b-img>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col v-if="douceEscale.right" md="1"></b-col>
      </b-row>
      <b-row class="col-md-12 ml-auto mr-auto p-0 contactus-2" id="contact">
        <b-col md="12" class="p-0">
          <iframe
            id="map"
            class="map"
            frameborder="0"
            style="border:0"
            :src="`https://www.google.fr/maps/embed/v1/place?q=place_id:EihSdWUgZHUgMWVyIE1haSwgNjMyMDAgTcOpbsOpdHJvbCwgRnJhbmNlIi4qLAoUChIJX4RIPPAh90cRjNEuXbwdyi8SFAoSCf3MrV_gIfdHERC_5cuvPAkE&key=${key}`"
            allowfullscreen
          ></iframe>
          <card type="contact" raised header-classes="text-center">
            <h3 slot="header" class="card-title">Nous Contacter</h3>
            <div class="row">
              <div class="col-md-8 pr-0">
                <info-section type="primary" icon="now-ui-icons tech_mobile">
                  <h4 class="info-title">Contact et Horaires</h4>
                  <p>
                    Nadège
                    <br />Vous accueille sur RDV
                    <br />
                    <i class="now-ui-icons tech_mobile"></i>
                    <a href="tel:+33613527000">+33 6 13 52 70 00</a>
                    <br />Lundis, mardis et vendredis - 09:00-19:00
                    <br />Jeudis - 09:00-14:00 Aprés-midi seulement sur rendez-vous
                    <br />Samedis - 08:30-16:00
                  </p>
                </info-section>
              </div>
              <div class="col-md-4 pr-0">
                <info-section type="primary" icon="now-ui-icons location_pin">
                  <h4 class="info-title">Adresse</h4>
                  <p>Douce Éscale</p>
                  <p>
                    Place du 1<sup>er</sup> Mai,
                    <br />63200 Ménétrol,
                    <br />France
                  </p>
                </info-section>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import DouceEscaleSoins from "../../../public/douceEscaleSoins.json";
import { Card, InfoSection } from "@/components";
import { API_KEY } from "@/constants";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY
  }
});

export default {
  name: "douce-escale",
  bodyClass: "landing-page",
  components: {
    Card,
    InfoSection
  },
  data: function() {
    return {
      douceEscaleSoins: DouceEscaleSoins,
      key: API_KEY,
      center: {
        lat: 40.748817,
        lng: -73.985428
      },
      options: {
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#e9e9e9"
              },
              {
                lightness: 17
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              },
              {
                lightness: 20
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 17
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 29
              },
              {
                weight: 0.2
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 18
              }
            ]
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              },
              {
                lightness: 16
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              },
              {
                lightness: 21
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#dedede"
              },
              {
                lightness: 21
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on"
              },
              {
                color: "#ffffff"
              },
              {
                lightness: 16
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: 36
              },
              {
                color: "#333333"
              },
              {
                lightness: 40
              }
            ]
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#f2f2f2"
              },
              {
                lightness: 19
              }
            ]
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#fefefe"
              },
              {
                lightness: 20
              }
            ]
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#fefefe"
              },
              {
                lightness: 17
              },
              {
                weight: 1.2
              }
            ]
          }
        ]
      }
    };
  },
  computed: {
    navigatorShare: function() {
      return navigator.share !== undefined;
    }
  },
  methods: {
    shareClass(right) {
      // it is the opposite for the share button group
      if (!right) return "p-0 text-right";
      else return "p-0 text-left";
    },
    share(category) {
      if (navigator.share) {
        navigator
          .share({
            title: "DouceEscaleInstitut.fr",
            text: "Douce Escale - Institut de Beauté pour Femme.",
            url: "https://douceescaleinstitut.fr/#" + category
          })
          .then(() => console.log("Successful share"))
          .catch(error => console.log("Error sharing", error));
      } else {
        console.log("Web Share API is not supported in your browser.");
      }
    }
  }
};
</script>
<style scoped>
.starter-page {
  min-height: calc(100vh - 79px);
}

.bottom-nav {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.share-button {
  position: relative;
  z-index: 999;
}

.card-header {
  margin: 5rem;
  text-align: center;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit:cover;
}

.brand-img {
  height: 60px;
}

@media screen and (max-width: 767px){
  .card-header {
    margin: 0.5rem;
    text-align: center;
  }
  .card-img {
    width: 100%;
    height: 20vh;
    object-fit:cover;
  }
  .brand-img {
    height: 30px;
    margin-bottom: 15px;
}
}
</style>

<template>
    <div id="app">
        <router-view name="header"/>
        <div>
            <router-view/>
        </div>
        <router-view name="footer"/>
    </div>
</template>
<script>
  export default {}
</script>


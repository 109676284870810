<template>
    <footer class="footer"
            :class="{[`footer-${type}`]: type}"
            :data-background-color="backgroundColor">
        <div class="container">
            <!--nav>
                <ul>
                    <li>
                        <a href="">
                            A Propos
                        </a>
                    </li>
                    <li>
                        <a href="">
                            Nous Contacter
                        </a>
                    </li>
                </ul>
            </nav-->
            <div class="copyright">
                Copyright {{year}} &copy; - Designed by <a href="mailto:laurent.peny@gmail.com">LCP</a>.
            </div>
        </div>
    </footer>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
      type: String
    },
    data(){
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
<style>
</style>

import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from 'bootstrap-vue'
import router from "./dERouter";
import "./registerServiceWorker";
//plugins
import NowUIKit from './plugins/now-ui-kit';

Vue.config.productionTip = false;

Vue.use(NowUIKit);
Vue.use(BootstrapVue)

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

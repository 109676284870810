<template>
  <navbar
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template slot-scope="{toggle, isToggled}">
      <router-link v-popover:popover1 class="navbar-brand" to="/">Douce Escale</router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="160"
        trigger="hover"
      >
        <div class="popover-body">Institut de beauté pour femme.</div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <drop-down tag="li" title=" Nos Soins" icon="now-ui-icons objects_diamond">
        <nav-link class="dropdown-item" v-for="(douceEscale, index) in douceEscaleSoins.douceEscale" :key="index" :href="`#${douceEscale.id}`">
          <i class="now-ui-icons shopping_bag-16"></i> {{douceEscale.category}}
        </nav-link>
      </drop-down>
      <li class="nav-item">
        <a class="nav-link" href="#contact">
          <i class="now-ui-icons objects_globe"></i>
          <p>Contact</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.facebook.com/Douce-Escale-110948277024020/" target="_blank">
          <i class="fab fa-facebook-square"></i>
          <p>Facebook</p>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://www.instagram.com/douceescale/" target="_blank">
          <i class="fab fa-instagram-square"></i>
          <p>Instagram</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import DouceEscaleSoins from '../../public/douceEscaleSoins.json'
import { DropDown, NavbarToggleButton, Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    DropDown,
    Navbar,
    NavbarToggleButton,
    NavLink,
    [Popover.name]: Popover
  },
  data: function() {
    return {
      douceEscaleSoins: DouceEscaleSoins
    };
  },
};
</script>

<style scoped>
</style>

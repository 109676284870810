import Vue from "vue";
import Router from "vue-router";
import DouceEscale from "./pages/douceEscale/DouceEscale.vue";
import DouceNavbar from "./layout/DouceNavbar.vue";
import DouceFooter from "./layout/DouceFooter.vue";

Vue.use(Router);

export default new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: "/",
      name: "Bienvenue Sur Douce Escale",
      components: {default: DouceEscale, header: DouceNavbar, footer: DouceFooter},
      props: {
        header: {colorOnScroll: 375},
        footer: {backgroundColor: 'black'}
      }
    }
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
});